import {
	type Action,
	createActionsHook,
	createContainer,
	createHook,
	createSelector,
	createStateHook,
	createStore,
} from 'react-sweet-state';

import { fg } from '@atlaskit/platform-feature-flags';
import {
	type EntityATI,
	type HydratedJiraIssue,
	isSearchCompassPartial,
	isSearchResultJiraIssue,
	type ProductCount,
	type ResultsSortField,
	type ResultsSortOrder,
	type SearchPageEdge,
	type SearchPageResults,
	SORT_FIELD_KEY,
	type TeamDetails,
} from '@atlassian/search-client';
import type { BackendExperiment } from '@atlassian/search-experiment';

import { QueryState } from '../../../common/constants/analytics';
import {
	type FilterId,
	getFilterIdOrdering,
	universalTypeFilterId,
} from '../../../common/constants/filters';
import type { CloudConfig } from '../../../common/constants/filters/cloud-config/types';
import type { SelectFilterOptionChange } from '../../../common/constants/filters/select-filter/types';
import type {
	AllFilterStates,
	AllFilterValues,
	Filters,
} from '../../../common/constants/filters/types';
import { TypeFilterValueKey } from '../../../common/constants/filters/universal-type';
import { type NounKeys } from '../../../common/constants/nouns';
import { ProductKeys } from '../../../common/constants/products';
import { ResultsStates } from '../../../common/constants/results';
import { type ThirdPartyConfigsBootstrap } from '../../../common/constants/schemas/3p-config';
import { type LastModifiedValue } from '../../../common/constants/schemas/query-params';
import type {
	AppendResultsArgs,
	ProductResultCount,
	SearchState,
	SearchStoreContainerProps,
} from '../../../common/constants/search';
import { type QueryParams } from '../../../common/types';
import { type FilterAttributes } from '../../../common/utils/events/types';
import { EmptyEntitiesError } from '../../../common/utils/fetch-error';
import { parseQueryParams } from '../../../common/utils/query-params';
import { getTeamId } from '../../../common/utils/teams';
import { isSelectFilter } from '../../filters/base-select-filter/utils';

import { getEntitiesForQuery as getUniversalEntitiesForQuery } from './filters/universal-type/utils';
import { FilterUtils } from './filters/utils';

const initialState = {
	// Search Input states
	inputQuery: '',
	searchQuery: '',

	// Product States
	primaryProduct: null,

	// Filter States
	filters: {},
	filterSelectionOrder: [],
	selectedProducts: [],
	availableProducts: [],
	allFilterExcludedProducts: [],

	// Rest of store states
	resultsSort: {
		field: 'relevance',
		order: 'DESC',
	},
	retryCount: 0,
	queryVersion: 0,
	queryUpdatedTime: undefined,
	isLoading: false,
	isJiraHydrationLoading: false,
	isCompassTeamsLoading: false,
	shownCount: 0,
	results: undefined,
	resultError: undefined,
	productResultCountMap: null,
	paginationTokens: [],
	isLoadingMore: false,
	leapQuery: {
		loading: false,
		candidates: [],
		titleIndex: 0,
	},
	requestedBackendExperiments: undefined,
} satisfies SearchState;

const convertQueryParamsToStringArray = (
	queryParams: QueryParams,
	keys: string[],
): Record<string, string[]> => {
	return keys.reduce((acc, key) => {
		if (queryParams[key] === undefined) {
			return acc;
		}
		if (Array.isArray(queryParams[key])) {
			return {
				...acc,
				[key]: queryParams[key],
			} as Record<string, string[]>;
		}
		if (typeof queryParams[key] === 'string' || typeof queryParams[key] === 'boolean') {
			return { ...acc, [key]: [(queryParams[key] as string | boolean).toString() as string] };
		}
		return acc;
	}, {});
};

const getUpdatedFilterSelectionOrder = (
	previousOrder: string[],
	newFilterId: string,
	selected?: boolean,
) => {
	let filterSelectionOrder = new Set([...previousOrder, newFilterId]);
	if (!selected) {
		filterSelectionOrder.delete(newFilterId);
	}

	return [...filterSelectionOrder];
};

/**
 * Only these keys can get set before the updateQueryParams loop when updateQueryParams=true in setSearchStoreState
 */
const safeStateKeys = ['filterSelectionOrder', 'resultsSort', 'retryCount'];

type SetSearchStoreStateOptions = {
	updateQueryParams?: boolean;
};
/**
 * Please use setSearchStoreState instead of setState within the Search Store controller.
 *
 * This is because we treat the URL as the source of truth for some state. Filter value states are set in the URL directly and then we listen to URL state changs to represent them in our state.
 *
 * @param newState - state properties to set
 * @param {boolean} options.updateQueryParams - [false] Whether a setState call should trigger an URL update
 *
 * Notes on updateQueryParams:
 *
 * When false, this function acts like a standard setState
 * When true, this function will allow only the {safeStateKeys} to be set and will then use the provided query/filter etc state to set the URL directly, relying on the URL observer to complete the loop
 *
 * Non-url updates should NEVER trigger the setQueryParams callback. Otherwise, this will cause infinite loops or extra render cycles.
 **/
const setSearchStoreState =
	(
		newState: Partial<SearchState>,
		options?: SetSearchStoreStateOptions,
	): Action<SearchState, SearchStoreContainerProps> =>
	({ getState, setState }, { setQueryParams, allFilters, config, availableProducts }) => {
		// TODO future refactoring: we should instead query the newState object for any URL-state changes, then clean up the setState we perform, then update the URL. Then a consumer of this function will not need to care about any of this and can simply pass `newState` as expected
		if (!options?.updateQueryParams) {
			setState({ ...newState, availableProducts });

			return;
		}

		// We still want to set variables in state that do NOT participate in the URL
		// Initially we'll allow-list this set, as the set of filters (the disallow set) will grow over time
		const safeNewState = Object.fromEntries(
			Object.entries(newState).filter(([key]) => safeStateKeys.includes(key)),
		);
		if (Object.keys(safeNewState).length > 0) {
			setState(safeNewState);
		}

		const { searchQuery: newSearchQuery, inputQuery: newInputQuery } = newState;
		const { inputQuery: oldInputQuery } = getState();

		const { selectedProducts, filters: allFilterStates } = {
			...getState(),
			...newState,
		} satisfies SearchState;

		const staticFilters = Object.values(allFilters).filter((f) => {
			const filterState = f.getInitialState(config).getState();
			return selectedProducts.length === 0
				? filterState.universal
				: filterState.products.some((product) => selectedProducts.includes(product));
		});

		const filterQueryParamValues = staticFilters.map((staticFilter) => {
			const filterState = allFilterStates[staticFilter.id];

			if (!staticFilter.isCompatible(filterState?.id)) {
				return {};
			}

			return staticFilter.withState(filterState, config).queryParams.get();
		});

		const filterQueryParams = Object.assign({}, ...filterQueryParamValues);
		const text = [newSearchQuery, newInputQuery, oldInputQuery].find(Boolean);

		const parsedQueryParams = parseQueryParams(
			{
				product: selectedProducts[0],
				text: text !== '' ? text : undefined,
				...filterQueryParams,
			},
			{ commaSeparatedValues: true },
		);
		setQueryParams(parsedQueryParams);
	};
export const unsafe__testingOnly_setSearchStoreState = setSearchStoreState;

const privateStoreActions = {
	initialiseStore:
		(): Action<SearchState, SearchStoreContainerProps> =>
		async (
			{ dispatch },
			{
				allFilters,
				queryParams,
				config,
				setQueryParams,
				availableProducts,
				allFilterExcludedProducts,
			},
		) => {
			const { getSelectedProducts, filtersByProduct, isMissingProductSelection } = FilterUtils(
				allFilters,
				{},
			);
			const selectedProducts = getSelectedProducts(queryParams);

			const cql = queryParams.cql?.toString();

			if (isMissingProductSelection(queryParams)) {
				setQueryParams({ ...queryParams, product: selectedProducts[0] });
				return;
			}

			const { staticFilterValues: staticFiltersByProduct } = filtersByProduct(selectedProducts);

			const resolvedQueryParams = await Promise.allSettled(
				staticFiltersByProduct.map((staticFilter) => {
					const queryParamsKeys = [staticFilter.queryParams.key];
					if (staticFilter.isSupportCustomValue && staticFilter.customValueFields) {
						queryParamsKeys.push(...staticFilter.customValueFields);
					}
					return staticFilter
						.getInitialState(config)
						.queryParams.set(convertQueryParamsToStringArray(queryParams, queryParamsKeys));
				}),
			);

			const filterStatesByProduct = resolvedQueryParams.flatMap((promise) => {
				switch (promise.status) {
					case 'fulfilled':
						return [promise.value.getState()];
					case 'rejected':
						return [];
				}
			});

			const { filterStates } = FilterUtils(staticFiltersByProduct, filterStatesByProduct);

			dispatch(
				setSearchStoreState(
					{
						filters: filterStates,
						selectedProducts,
						inputQuery: String(queryParams.text ?? ''),
						searchQuery: String(queryParams.text ?? ''),
						originalQuery: String(queryParams.originalQuery ?? ''),
						availableProducts,
						allFilterExcludedProducts,
						cql,
					},
					{
						updateQueryParams: false, // onInit should not update the query params
					},
				),
			);
		},
	// TODO QS-4438 move this out to be private/unsafe like setSearchStoreState
	updateStore:
		(): Action<SearchState, SearchStoreContainerProps> =>
		async ({ dispatch, getState }, { allFilters, queryParams, config, availableProducts }) => {
			const { filters: filterStates } = getState();

			const { getSelectedProducts, filtersByProduct } = FilterUtils(allFilters, filterStates);
			const selectedProducts = getSelectedProducts(queryParams);
			const cql = queryParams.cql?.toString();

			const { staticFilterValues: staticFiltersByProduct, filterStates: filterStatesByProduct } =
				filtersByProduct(selectedProducts);

			const resolvedQueryParams = await Promise.allSettled(
				staticFiltersByProduct.map((staticFilter) => {
					const filterState = filterStatesByProduct[staticFilter.id];
					const queryParamsKeys = [staticFilter.queryParams.key];
					if (staticFilter.isSupportCustomValue && staticFilter.customValueFields) {
						queryParamsKeys.push(...staticFilter.customValueFields);
					}
					const queryParamStringArray = convertQueryParamsToStringArray(
						queryParams,
						queryParamsKeys,
					);

					if (!staticFilter.isCompatible(filterState?.id)) {
						return staticFilter.getInitialState(config).queryParams.set(queryParamStringArray);
					}

					return staticFilter.withState(filterState, config).queryParams.set(queryParamStringArray);
				}),
			);

			const fullfilledFilterStates = resolvedQueryParams.flatMap((promise) => {
				switch (promise.status) {
					case 'fulfilled':
						return [promise.value.getState()];
					case 'rejected':
						return [];
				}
			});

			const { getFiltersWithSelection, filterStates: resolvedFilterStates } = FilterUtils(
				staticFiltersByProduct,
				fullfilledFilterStates,
			);

			// In order, ensure all filters with values are added to selection
			// Ensures filters enter selection order from resume-from-url
			// TODO: this should be done with the global ordering, but we don't have that in constants yet
			let { filterSelectionOrder } = getState();
			getFiltersWithSelection().map((filterId: FilterId) => {
				filterSelectionOrder = getUpdatedFilterSelectionOrder(
					filterSelectionOrder,
					filterId,
					resolvedFilterStates[filterId]?.hasSelection,
				);
			});

			dispatch(
				setSearchStoreState(
					{
						filters: resolvedFilterStates,
						selectedProducts: getSelectedProducts(queryParams),
						filterSelectionOrder: filterSelectionOrder,
						inputQuery: String(queryParams.text ?? ''),
						searchQuery: String(queryParams.text ?? ''),
						originalQuery: String(queryParams.originalQuery ?? ''),
						availableProducts,
						paginationTokens: [],
						cql,
					},
					{ updateQueryParams: false }, // onUpdate lifecycle should never update the query params
				),
			);
		},
	setFilter:
		(
			state: AllFilterStates,
			shouldSetQueryParams?: boolean,
		): Action<SearchState, SearchStoreContainerProps> =>
		async ({ dispatch, getState }, { allFilters, config }) => {
			const staticFilter = allFilters[state.id];
			const { filters: allFiltersState } = getState();

			if (!staticFilter.isCompatible(staticFilter.id)) {
				return;
			}

			const filterWithState = staticFilter.withState(state, config).getState();
			dispatch(
				setSearchStoreState(
					{
						filters: {
							...allFiltersState,
							[staticFilter.id]: filterWithState,
						},
					},
					{
						updateQueryParams: shouldSetQueryParams,
					},
				),
			);
		},
	updateFilterSelectionOrder:
		(filterId: string, selected: boolean): Action<SearchState, SearchStoreContainerProps> =>
		async ({ dispatch, getState }) => {
			const { filterSelectionOrder: oldSelectionOrder } = getState();

			const newFilterSelectionOrder = getUpdatedFilterSelectionOrder(
				oldSelectionOrder,
				filterId,
				selected,
			);

			dispatch(
				setSearchStoreState({
					filterSelectionOrder: newFilterSelectionOrder,
				}),
			);
		},
} as const;

export const actions = {
	loadInitialSelectFilterOptions:
		(props: { filterId: string }): Action<SearchState, SearchStoreContainerProps> =>
		async ({ getState, dispatch }, { allFilters, config }) => {
			const staticSelectFilter = allFilters[props.filterId];
			const allFiltersState = getState().filters;
			const filterState = allFiltersState[props.filterId];

			if (
				!staticSelectFilter.isCompatible(filterState?.id) ||
				!isSelectFilter(staticSelectFilter.type)
			) {
				return;
			}

			const newFilter = staticSelectFilter.withState(filterState, config);

			await newFilter.loadInitialOptions((stateChange) => {
				dispatch(privateStoreActions.setFilter(stateChange));
			});
		},
	clearSelectFilterOptions:
		(props: { filterId: string }): Action<SearchState, SearchStoreContainerProps> =>
		async ({ getState, dispatch }, { allFilters, config }) => {
			const staticSelectFilter = allFilters[props.filterId];
			const allFiltersState = getState().filters;
			const filterState = allFiltersState[props.filterId];

			if (
				!staticSelectFilter.isCompatible(filterState?.id) ||
				!isSelectFilter(staticSelectFilter.type)
			) {
				return;
			}

			const newFilterState = staticSelectFilter.withState(filterState, config).clear().getState();

			dispatch(privateStoreActions.setFilter(newFilterState, true));
			dispatch(
				privateStoreActions.updateFilterSelectionOrder(props.filterId, newFilterState.hasSelection),
			);
			dispatch(
				setSearchStoreState({
					productResultCountMap: null,
				}),
			);
		},
	setSelectFilterOption:
		(props: {
			filterId: string;
			optionChange: SelectFilterOptionChange | SelectFilterOptionChange[];
			customValue?: Record<string, string>;
		}): Action<SearchState, SearchStoreContainerProps> =>
		async ({ getState, dispatch }, { allFilters, config }) => {
			const staticSelectFilter = allFilters[props.filterId];
			const allFiltersState = getState().filters;
			const filterState = allFiltersState[props.filterId];

			if (
				!staticSelectFilter.isCompatible(filterState?.id) ||
				!isSelectFilter(staticSelectFilter.type)
			) {
				return;
			}

			const newFilterState = staticSelectFilter
				.withState(filterState, config)
				.setOption(props.optionChange, props.customValue)
				.getState();

			dispatch(privateStoreActions.setFilter(newFilterState, true));
			dispatch(
				privateStoreActions.updateFilterSelectionOrder(props.filterId, newFilterState.hasSelection),
			);
			dispatch(
				setSearchStoreState({
					productResultCountMap: null,
				}),
			);
		},
	setSelectFilterLookupInput:
		(props: { filterId: string; query: string }): Action<SearchState, SearchStoreContainerProps> =>
		async ({ getState, dispatch }, { allFilters, config }) => {
			const staticSelectFilter = allFilters[props.filterId];
			const allFiltersState = getState().filters;
			const filterState = allFiltersState[props.filterId];

			if (
				!staticSelectFilter.isCompatible(filterState?.id) ||
				!isSelectFilter(staticSelectFilter.type)
			) {
				return;
			}

			await staticSelectFilter
				.withState(filterState, config)
				.setLookupInput(props.query, (stateChange) => {
					dispatch(privateStoreActions.setFilter(stateChange));
				});
		},
	setBooleanFilterValue:
		(
			props: {
				filterId: string;
				value: boolean;
			},
			privateActions = privateStoreActions,
		): Action<SearchState, SearchStoreContainerProps> =>
		({ getState, dispatch }, { allFilters, config }) => {
			const staticBooleanFilter = allFilters[props.filterId];
			const allFiltersState = getState().filters;
			const filterState = allFiltersState[props.filterId];

			if (!staticBooleanFilter.isCompatible(filterState?.id)) {
				return;
			}

			if (staticBooleanFilter.type !== 'boolean') {
				return;
			}

			const newBooleanFilterState = staticBooleanFilter
				.withState(filterState, config)
				.setValue(props.value)
				.getState();

			dispatch(privateActions.setFilter(newBooleanFilterState, true));
			dispatch(
				privateActions.updateFilterSelectionOrder(
					props.filterId,
					newBooleanFilterState.hasSelection,
				),
			);
			dispatch(
				setSearchStoreState({
					productResultCountMap: null,
				}),
			);
		},
	clearAllFilters:
		(): Action<SearchState, SearchStoreContainerProps> =>
		({ getState, dispatch }, { allFilters, config }) => {
			const { filters: allFilterStates } = getState();
			const staticFilters = Object.values(allFilters);

			dispatch(
				setSearchStoreState(
					{
						filters: clearFilters(allFilterStates, staticFilters, config),
						selectedProducts: [],
						filterSelectionOrder: [],
					},
					{ updateQueryParams: true },
				),
			);
		},
	clearAllAvailableFilters:
		(): Action<SearchState, SearchStoreContainerProps> =>
		({ getState, dispatch }, { allFilters, config }) => {
			const staticFilters = Object.values(allFilters);
			const { filters: allFilterStates } = getState();

			dispatch(
				setSearchStoreState(
					{
						filters: clearFilters(allFilterStates, staticFilters, config),
						filterSelectionOrder: [],
					},
					{ updateQueryParams: true },
				),
			);
		},
	// Product filter actions
	selectProductFilter:
		(productKey?: ProductKeys): Action<SearchState, SearchStoreContainerProps> =>
		({ getState, dispatch }) => {
			const { selectedProducts, filters, filterSelectionOrder } = getState();
			if (productKey && selectedProducts.includes(productKey)) {
				return;
			}

			const persistedFilters: Record<string, AllFilterStates> = {};

			// Persist selected universal filters if they are relevant to the selected product
			for (const [id, filter] of Object.entries(filters)) {
				if (!filter) {
					continue;
				}

				if (
					filter.universal &&
					filter.hasSelection &&
					(!productKey || (productKey && filter.products.includes(productKey)))
				) {
					persistedFilters[id] = filter;
				}
			}

			// Type is a universal filter, but type needs to be cleared if we're filtering away from Atlas
			// This is because selecting a global noun sets product AND type values
			if (selectedProducts.includes(ProductKeys.Atlas)) {
				delete persistedFilters[universalTypeFilterId];
			}

			// Universal filters being persisted also retain their ordering
			const newFilterSelectionOrder = filterSelectionOrder.filter((f) => {
				return Object.keys(persistedFilters).includes(f);
			});

			dispatch(
				setSearchStoreState(
					{
						selectedProducts: productKey ? [productKey] : [],
						filterSelectionOrder: newFilterSelectionOrder,
						filters: persistedFilters,
						resultsSort: initialState.resultsSort,
					},
					{ updateQueryParams: true },
				),
			);
		},
	unselectProductFilter:
		(): Action<SearchState, SearchStoreContainerProps> =>
		({ dispatch }) => {
			dispatch(actions.selectProductFilter(undefined));
		},
	// Search Input Actions
	setInputQuery:
		(inputQuery: string): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(setSearchStoreState({ inputQuery: inputQuery.trim() }));
		},
	setSearchQuery:
		(searchQuery: string): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({ searchQuery: searchQuery.trim() }, { updateQueryParams: true }),
			);
		},
	triggerSearch:
		(): Action<SearchState> =>
		({ getState, dispatch }) => {
			const { inputQuery, searchQuery, retryCount, productResultCountMap } = getState();
			dispatch(
				setSearchStoreState({
					productResultCountMap: searchQuery === inputQuery ? productResultCountMap : null,
				}),
			);
			dispatch(
				setSearchStoreState(
					{
						searchQuery: inputQuery,
						retryCount: searchQuery === inputQuery ? retryCount + 1 : 0,
					},
					{ updateQueryParams: true },
				),
			);
		},
	retrySearch:
		(): Action<SearchState> =>
		({ dispatch, getState }) => {
			dispatch(setSearchStoreState({ retryCount: getState().retryCount + 1 }));
		},
	// Sorting actions
	setSortField:
		(sortField: ResultsSortField): Action<SearchState> =>
		({ dispatch, getState }) => {
			const { resultsSort } = getState();
			dispatch(
				setSearchStoreState({
					resultsSort: { ...resultsSort, field: sortField },
					paginationTokens: [],
				}),
			);
		},
	setSortOrder:
		(sortOrder: ResultsSortOrder): Action<SearchState> =>
		({ dispatch, getState }) => {
			const { resultsSort } = getState();
			dispatch(
				setSearchStoreState({
					resultsSort: { ...resultsSort, order: sortOrder },
					paginationTokens: [],
				}),
			);
		},
	resetSort:
		(): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					resultsSort: initialState.resultsSort,
					paginationTokens: [],
				}),
			);
		},
	// Rest of Search Actions
	setQueryUpdatedTime:
		(): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(setSearchStoreState({ queryUpdatedTime: Date.now() }));
		},
	resetQueryVersion:
		(): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(setSearchStoreState({ queryVersion: 0 }));
		},
	incrementQueryVersion:
		(): Action<SearchState> =>
		({ getState, setState }) => {
			const { queryVersion } = getState();
			setState({ queryVersion: queryVersion + 1 });
		},
	setIsLoading:
		(isLoading: boolean): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(setSearchStoreState({ isLoading }));
		},
	// Results actions
	setResults:
		(
			results?: SearchState['results'],
			error?: SearchState['resultError'],
			requestId?: SearchState['requestId'],
		): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					isLoading: false,
					results,
					resultError: error,
					requestId,
				}),
			);
		},

	appendMoreResults:
		(results: AppendResultsArgs): Action<SearchState> =>
		({ dispatch, getState }) => {
			const { results: currentResults } = getState();
			if (!currentResults || !results) {
				return;
			}

			const currentEdges = currentResults.edges;
			const newEdges = results.edges;

			const newResults = {
				...currentResults,
				edges: currentEdges.concat(newEdges),
				pageInfo: results?.pageInfo,
			};

			dispatch(
				setSearchStoreState({
					results: newResults,
					isLoadingMore: false,
				}),
			);
		},
	setFetchFromCursor:
		(cursor: string): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					paginationTokens: [{ cursor }],
				}),
			);
		},
	setIsLoadingMore:
		(isLoadingMore: boolean): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					isLoadingMore,
				}),
			);
		},
	setJiraIssueFieldsLoading:
		(isLoading: boolean): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					isJiraHydrationLoading: isLoading,
				}),
			);
		},
	setJiraIssueDetails:
		(issueData: HydratedJiraIssue[]): Action<SearchState> =>
		({ dispatch, getState }) => {
			const { results } = getState();
			let hydratedResults: SearchPageEdge[] = [];
			if (results?.edges && results.edges.length > 0 && issueData.length > 0) {
				hydratedResults = [...results.edges].map((edge) => {
					if (isSearchResultJiraIssue(edge.node)) {
						const id = edge.node.id;
						const hydratedIssue = issueData.find((issue) => issue.id === id);

						if (edge.node.status && hydratedIssue) {
							edge.node.status.name = hydratedIssue.statusField.status.name;
							edge.node.status.colorName =
								hydratedIssue.statusField.status.statusCategory?.colorName;
						}
					}
					return edge;
				});
				dispatch(
					setSearchStoreState({
						results: {
							...results,
							edges: hydratedResults,
						},
					}),
				);
			}
		},
	setCompassTeamsLoading:
		(isLoading: boolean): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					isCompassTeamsLoading: isLoading,
				}),
			);
		},
	setCompassTeams:
		(teamData: TeamDetails[]): Action<SearchState> =>
		({ dispatch, getState }) => {
			const { results } = getState();
			let resultsWithTeamNames: SearchPageEdge[] = [];
			if (results?.edges && results.edges.length > 0 && teamData.length > 0) {
				resultsWithTeamNames = [...results.edges].map((edge) => {
					if (isSearchCompassPartial(edge.node)) {
						const teamId = getTeamId(edge.node.ownerId);
						const team = teamData.find((team) => team.id === teamId);
						edge.node.ownerName = team?.displayName;
					}
					return edge;
				});
				dispatch(
					setSearchStoreState({
						results: {
							...results,
							edges: resultsWithTeamNames,
						},
					}),
				);
			}
		},

	resetResults:
		(): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					isLoading: false,
					shownCount: 0,
					results: undefined,
					resultError: undefined,
					requestId: undefined,
				}),
			);
		},
	setAutoCorrection:
		(autoCorrection?: string): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(setSearchStoreState({ autoCorrection }));
		},
	setLeapQuery:
		({
			candidates,
			titleIndex,
			loading,
		}: {
			candidates: string[];
			titleIndex: number;
			loading: boolean;
		}): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(
				setSearchStoreState({
					leapQuery: {
						loading,
						candidates,
						titleIndex,
					},
				}),
			);
		},
	setProductResultCount:
		(totalCounts?: ProductCount[]): Action<SearchState> =>
		({ dispatch, getState }) => {
			if (totalCounts === undefined) {
				dispatch(
					setSearchStoreState({
						productResultCountMap: null,
					}),
				);
				return;
			}

			const {
				filters: filterStates,
				availableProducts,
				productResultCountMap: oldProductResultCountMap,
			} = getState();

			const filtersWithSelection = Object.values(filterStates).filter(
				(filter) => filter?.hasSelection,
			);

			const productsCorrespondingToAllSelectedFilters = filtersWithSelection.length
				? availableProducts.filter((product) =>
						filtersWithSelection.every((filter) => filter?.products?.includes(product)),
					)
				: availableProducts;
			const resultsCountsAdjustedForSelectedFilters = totalCounts.map((resultCount) => {
				if (
					productsCorrespondingToAllSelectedFilters.includes(resultCount.product as ProductKeys)
				) {
					return resultCount;
				} else {
					return {
						...resultCount,
						count: 0,
					};
				}
			});

			const productResultCountMap =
				resultsCountsAdjustedForSelectedFilters.reduce<ProductResultCount>(
					(acc, { product, count }) => {
						if (product) {
							acc[product] = count;
						}
						return acc;
					},
					{},
				);

			dispatch(
				setSearchStoreState({
					productResultCountMap: { ...oldProductResultCountMap, ...productResultCountMap },
				}),
			);
		},
	// For backwards compatibility
	clearHybridFilters:
		(): Action<SearchState> =>
		({ dispatch }) => {
			dispatch(actions.clearAllFilters());
		},

	/**
	 * Considered unsafe since it's using queryParams to change the state.
	 * Ideally we should set it directly in the state, but that requires introducing a new action.
	 * This is because the cooresponding "Type" filter that this selects isn't available or easy to set from outside
	 * The product=townsquare&type=project will correctly hydrate the filter from the URL after params are set though!
	 *
	 * We hope to improve Atlassian Home / global noun handling after GA
	 *
	 * @param value: a noun to be set
	 */
	unsafe_setAtlasNouns:
		(value?: NounKeys): Action<SearchState, SearchStoreContainerProps> =>
		({ dispatch }, { queryParams, setQueryParams }) => {
			dispatch(
				setSearchStoreState({
					productResultCountMap: null,
				}),
			);

			if (!value) {
				const { product, type, ...queryParamsWithResetNounFilter } = queryParams;
				setQueryParams({ ...queryParamsWithResetNounFilter });
			} else {
				const { text, originalQuery, lastModified } = queryParams;
				// We explicitly only keep text and lastModified
				setQueryParams({
					product: ProductKeys.Atlas,
					type: value,
					text: text as string,
					originalQuery: originalQuery as string,
					lastModified: lastModified as LastModifiedValue,
				});
			}
		},
	setRequestedBackendExperiments:
		(
			requestedBackendExperiments: BackendExperiment,
		): Action<SearchState, SearchStoreContainerProps> =>
		({ setState }) => {
			if (!fg('platform_search-scaleable-statsig-layers')) {
				return;
			}

			const experimentLayers = requestedBackendExperiments?.experimentLayers?.map((layer) => {
				const { analyticsDefinitions, definitions, ...rest } = layer;
				return {
					...rest,
					definitions: analyticsDefinitions,
				};
			});

			setState({
				requestedBackendExperiments: {
					experimentLayers,
				},
			});
		},
} as const;

const clearFilters = (
	allFilterStates: Record<string, AllFilterStates | undefined>,
	filters: AllFilterValues[],
	config: CloudConfig,
) => {
	const clearedFilters = filters.map((filter) => {
		const filterState = allFilterStates[filter.id];

		if (!filter.isCompatible(filterState?.id)) {
			return [];
		}

		return [filter.id, filter.withState(filterState, config).clear().getState()];
	});

	return Object.fromEntries(clearedFilters) as SearchState['filters'];
};

type TActions = typeof actions;

// Keep store private and use hooks to access state and actions
const Store = createStore<SearchState, TActions>({
	initialState,
	actions,
	name: 'search',
});

export const useFilterActions = createActionsHook(Store);

export const SearchStoreContainer = createContainer<
	SearchState,
	TActions,
	SearchStoreContainerProps
>(Store, {
	onInit:
		() =>
		({ dispatch }) => {
			dispatch(privateStoreActions.initialiseStore());
		},
	onUpdate:
		() =>
		({ dispatch }) => {
			dispatch(privateStoreActions.updateStore());
		},
	onCleanup:
		() =>
		({ setState }) => {
			setState(initialState);
		},
});

// Note - This doesnt trigger a re-render
export const useSearch = createHook(Store);

export const useSearchActions = createActionsHook(Store);

export const useFilters = createStateHook(Store, {
	selector: (state) => state.filters,
});

// Hooks
export const useInputQuery = createHook(Store, {
	selector: (state) => state.inputQuery,
});

export const useSearchQuery = createHook(Store, {
	selector: (state) => state.searchQuery,
});

export const useOriginalQuery = createHook(Store, {
	selector: (state) => state.originalQuery,
});

export const useAutoCorrection = createHook(Store, {
	selector: (state) => state.autoCorrection,
});

export const useRetryCount = createHook(Store, {
	selector: (state) => state.retryCount,
});

export const useProductResultCountMap = createHook(Store, {
	selector: (state) => state.productResultCountMap,
});

export const useABTest = createHook(Store, {
	selector: (state) => state.results?.abTest,
});

export const useQueryInfo = createHook(Store, {
	selector: (state) => state.results?.queryInfo,
});

export const useQueryState = createHook(Store, {
	selector: (state) => (state.searchQuery ? QueryState.POSTQUERY : QueryState.PREQUERY),
});

export const useQueryUpdatedTime = createHook(Store, {
	selector: (state) => state.queryUpdatedTime,
});

export const useResults = createHook(Store, {
	selector: (state) => state.results,
});

export const useResultsError = createHook(Store, {
	selector: (state) => state.resultError,
});

export const useResultsRequestId = createHook(Store, {
	selector: (state) => state.requestId,
});

export const useIsLoading = createHook(Store, {
	selector: (state) => state.isLoading,
});

export const useResultsSort = createHook(Store, {
	selector: (state) => state.resultsSort,
});

export const useQueryVersion = createHook(Store, {
	selector: (state) => state.queryVersion,
});

export const useAllFilterExcludedProducts = createStateHook(Store, {
	selector: (state) => state.allFilterExcludedProducts,
});

/**
 * A hook to determine the state of search results on a search page.
 *
 * NOTE: It is possible for SearchPageQuery to contain search results AND errors within the GraphQL
 * response. In this case, it is expected for FPS to display search results even if there are
 * downstream errors. To prevent this issue, `ResultsStates.RESULTS` state should ALWAYS be above
 * `ResultsState.ERROR`. See PIR-21043 for the investigation.
 *
 */
export const useResultsState = createStateHook(Store, {
	selector: createSelector(
		[(state) => state.results, (state) => state.isLoading, (state) => state.resultError],
		(results, isLoading, resultError): ResultsStates =>
			getResultsState({ results, isLoading, resultError }),
	),
});

export const getResultsState = ({
	results,
	isLoading,
	resultError,
}: {
	results: SearchPageResults | undefined;
	isLoading: boolean;
	resultError: Error | undefined;
}) => {
	if (isLoading) {
		return ResultsStates.IS_LOADING;
	}

	if (results && results.edges && results.edges.length > 0) {
		return ResultsStates.RESULTS;
	}

	// If there are no entities for a query, an error will throw
	// when no entities for query is equivalent to no results
	if (
		resultError instanceof EmptyEntitiesError ||
		(results && (results.edges ?? []).length === 0)
	) {
		return ResultsStates.NO_RESULTS;
	}

	if (resultError) {
		return ResultsStates.ERROR;
	}

	if (!results) {
		return ResultsStates.INITIAL;
	}

	return ResultsStates.ERROR;
};

// filter hooks
export const useSelectedProducts = createHook(Store, {
	selector: (state) => state.selectedProducts,
});

export const useSelectedProduct = createHook(Store, {
	selector: (state) => state.selectedProducts[0],
});

export const useIsJiraHydrationLoading = createStateHook(Store, {
	selector: (state) => state.isJiraHydrationLoading,
});

export const useIsCompassTeamsLoading = createStateHook(Store, {
	selector: (state) => state.isCompassTeamsLoading,
});

export const useIsSearchReady = createStateHook(Store, {
	selector: (state) => state.availableProducts && state.availableProducts.length > 0,
});

export const usePageEndCursor = createStateHook(Store, {
	selector: (state) => state.results?.pageInfo?.endCursor,
});

export const useFetchFromCursor = createStateHook(Store, {
	selector: (state) => state.paginationTokens[0]?.cursor,
});

export const useIsLoadingMore = createStateHook(Store, {
	selector: (state) => state.isLoadingMore,
});

export const useProducts = createStateHook(Store, {
	selector: createSelector(
		[(state) => state.selectedProducts, (state) => state.availableProducts],
		(selectedProducts, availableProducts) => {
			return {
				selectedProduct: selectedProducts.at(0),
				filteredProducts: selectedProducts[0] ? [selectedProducts[0]] : availableProducts,
				availableProducts,
			};
		},
	),
});

export const getUniversalTypeEntities = (
	productsToSearch: ProductKeys[],
	typeFilter: AllFilterStates | undefined,
	thirdPartyConfigs: ThirdPartyConfigsBootstrap,
	availableProducts: ProductKeys[],
	dropAttachmentsComments: boolean,
) => {
	// This early return is to appease Typescript. The typeFilter is always defined and is always single select.
	if (!typeFilter || !(typeFilter.type === 'single-select')) {
		return {
			selectedEntities: [],
			entitiesForQuery: [],
			entitiesForResultsCountQuery: [],
		};
	}
	const defaultOptionValue = typeFilter.options?.find((option) => option.isDefault)?.value;

	const selectedTypes = (typeFilter.value as TypeFilterValueKey[])
		.filter((value) => value !== defaultOptionValue)
		.filter(Boolean);

	const { entitiesForQuery, entitiesForResultsCountQuery } = getUniversalEntitiesForQuery(
		productsToSearch,
		selectedTypes,
		thirdPartyConfigs,
		availableProducts,
		dropAttachmentsComments,
	);

	return {
		selectedEntities: selectedTypes,
		entitiesForQuery: entitiesForQuery,
		entitiesForResultsCountQuery,
	};
};

type UseTypeEntitiesResult = {
	selectedEntities: string[];
	entitiesForQuery: EntityATI[];
	entitiesForResultsCountQuery: EntityATI[];
};

export type GetTypeTypeEntitiesState = Pick<
	SearchState,
	'availableProducts' | 'filters' | 'selectedProducts' | 'allFilterExcludedProducts'
>;

export type GetTypeTypeEntitiesProps = {
	thirdPartyConfigs: ThirdPartyConfigsBootstrap;
	dropAttachmentsComments: boolean;
};

export const getTypeEntities = (
	{
		availableProducts,
		filters,
		selectedProducts,
		allFilterExcludedProducts,
	}: GetTypeTypeEntitiesState,
	{ thirdPartyConfigs, dropAttachmentsComments }: GetTypeTypeEntitiesProps,
) => {
	// Filter out excluded products from "All" filter
	const allFilterIncludedProducts = availableProducts.filter(
		(product) => !allFilterExcludedProducts.includes(product),
	);
	const productsToSearch = selectedProducts[0] ? [selectedProducts[0]] : allFilterIncludedProducts;
	const typeFilter = filters[universalTypeFilterId];

	return getUniversalTypeEntities(
		productsToSearch,
		typeFilter,
		thirdPartyConfigs,
		availableProducts,
		dropAttachmentsComments,
	);
};

export const useTypeEntities = createStateHook(Store, {
	selector: createSelector(
		[
			(state) => state.availableProducts,
			(state) => state.selectedProducts,
			(state) => state.allFilterExcludedProducts,
			(state) => state.filters[universalTypeFilterId],
			(
				_,
				props: { thirdPartyConfigs: ThirdPartyConfigsBootstrap; dropAttachmentsComments: boolean },
			) => props,
		],
		(
			availableProducts,
			selectedProducts,
			allFilterExcludedProducts,
			typeFilter,
			props,
		): UseTypeEntitiesResult => {
			const { thirdPartyConfigs, dropAttachmentsComments } = props;

			// Filter out excluded products from "All" filter
			const allFilterIncludedProducts = availableProducts.filter(
				(product) => !allFilterExcludedProducts.includes(product),
			);

			const productsToSearch = selectedProducts[0]
				? [selectedProducts[0]]
				: allFilterIncludedProducts;

			return getUniversalTypeEntities(
				productsToSearch,
				typeFilter,
				thirdPartyConfigs,
				availableProducts,
				dropAttachmentsComments,
			);
		},
	),
});

export const useSelectFilterStore = createHook(Store, {
	selector: (state, props: { filterId: string }) => {
		const filter = state.filters[props.filterId];

		if (filter === undefined) {
			return undefined;
		}

		if (!isSelectFilter(filter.type)) {
			throw new Error('Not a select filter');
		}

		return {
			...filter,
			selectedProducts: state.selectedProducts,
			availableProducts: state.availableProducts,
			allFilterExcludedProducts: state.allFilterExcludedProducts,
		};
	},
});

export const useBooleanFilterStore = createHook(Store, {
	selector: (state, props: { filterId: string }) => {
		const filter = state.filters[props.filterId];

		if (filter === undefined) {
			return undefined;
		}

		if (filter?.type !== 'boolean') {
			throw new Error('Not a boolean filter');
		}

		return filter;
	},
});

export const getUniversalFilters = (filters: Filters) => {
	const filterIdOrdering = getFilterIdOrdering();
	return filterIdOrdering.flatMap((f) => {
		const filter = filters[f];
		return filter && filter.universal ? [filter] : [];
	});
};

export const getSelectedProductFilters = (filters: Filters, selectedProducts: ProductKeys[]) => {
	const filterIdOrdering = getFilterIdOrdering();
	return filterIdOrdering.flatMap((f) => {
		const filter = filters[f];
		return filter && selectedProducts.some((product) => filter?.products.includes(product))
			? [filter]
			: [];
	});
};

/**
 * All available filters in the initial ordering
 */
const getAllAvailableFilters = (filters: Filters, selectedProducts: ProductKeys[]) => {
	return selectedProducts.length === 0
		? getUniversalFilters(filters)
		: getSelectedProductFilters(filters, selectedProducts);
};

/**
 * All available filters are always returned for inline, but the ordering depends on selection
 * The caller UI is responsible for deciding how many can be rendered by available width
 */
const getFilterIdsForInline = (
	filters: Filters,
	selectedProducts: ProductKeys[],
	filterSelectionOrder: string[],
): string[] => {
	const allAvailableFilterIds = getAllAvailableFilters(filters, selectedProducts).map(
		(a) => a.id as string,
	);

	// using the selection order, filter from the available (removes any invalid filters to show)
	let filtersForInline = filterSelectionOrder.filter((f) => allAvailableFilterIds.includes(f));

	// then simply add the rest back (with a set for deduplication)
	return [...new Set([...filtersForInline, ...allAvailableFilterIds])];
};

export const useFiltersToDisplay = createHook(Store, {
	selector: ({ filters, selectedProducts, filterSelectionOrder }) => {
		// All filters always show in the dropdown, in order
		const filtersInDropdown = getAllAvailableFilters(filters, selectedProducts).map((f) => f.id);

		const filtersInLine = getFilterIdsForInline(filters, selectedProducts, filterSelectionOrder);

		return {
			filtersInDropdown,
			filtersInLine,
			filterSelectionOrder,
		};
	},
});

export const useSelectedFilterCount = createHook(Store, {
	selector: ({ selectedProducts, filters, resultsSort }) => {
		const selectedFilters = Object.values(filters)
			.filter((filter) => filter?.hasSelection)
			.filter((filter) => {
				const isAtlasSelected = selectedProducts.includes(ProductKeys.Atlas);
				const isTypeFilter = filter?.id === universalTypeFilterId;
				return isAtlasSelected ? !isTypeFilter : true;
			});

		const { field } = resultsSort;
		const isNotSortedByDefault = field !== SORT_FIELD_KEY.RELEVANCE;
		return {
			selectedFilterCount: selectedFilters.length + (isNotSortedByDefault ? 1 : 0),
		};
	},
});

export const useFilterAttributesForTracking = createStateHook(Store, {
	selector: ({ filters }) => {
		const filterAttributes = Object.values(filters)
			.flatMap((filter) => {
				return filter ? [filter] : [];
			})
			.map((filter): FilterAttributes => {
				const filterType = filter.type;
				switch (filterType) {
					case 'boolean':
						return {
							filters: [filter.id],
							filterSelections: filter.value
								? [
										{
											value: String(filter.value),
											isSmartDefault: false,
											filterId: filter.id,
										},
									]
								: [],
						};
					case 'multi-select':
					case 'single-select':
						return {
							filters: [filter.id],
							filterSelections: filter.options
								.filter((filterOption) => filterOption.isSelected)
								.map((filterOption) => ({
									value: filterOption.trackingKey || filterOption.value,
									isSmartDefault: filterOption.isSuggested,
									filterId: filter.id,
								})),
						};
					default:
						return filterType satisfies never;
				}
			})
			.filter((attributes) => attributes.filterSelections.length)
			.reduce(
				(acc, curr): FilterAttributes => {
					return {
						filters: [...acc.filters, ...curr.filters],
						filterSelections: [...acc.filterSelections, ...curr.filterSelections],
					};
				},
				{ filters: [], filterSelections: [] },
			);

		return filterAttributes;
	},
});

export const useCQL = createStateHook(Store, {
	selector: ({ cql }) => cql,
});

export const useAnyFilterSelected = createHook(Store, {
	selector: ({ selectedProducts, filters }) => {
		const hasSelectedProduct = selectedProducts[0];
		if (hasSelectedProduct) {
			return true;
		}

		return Object.values(filters).some((filter) => filter?.hasSelection);
	},
});

export const useLeapQueryResult = createStateHook(Store, {
	selector: ({ leapQuery }) => leapQuery,
});

export const useRequestedBackendExperiments = createHook(Store, {
	selector: (state) => ({
		backendExperiments: state.requestedBackendExperiments,
	}),
});
